import { DEFAULT_PAGES_CONFIGURATION } from 'services/default-pages-configuration.service';

import { RoutePath } from 'routes/route-path.constant';
import { ExperimentsKeys } from 'constants/experiments';
import { LocalStorageKeys, MultipleLocalStorageKeys } from 'constants/local-storage';

export const ABPagesConfiguration = () => {
    let finalConfiguration = Object.assign({}, DEFAULT_PAGES_CONFIGURATION);

    const mg_personal_statement_screens = localStorage.getItem(ExperimentsKeys.mg_personal_statement_screens) || '0';
    const mg_body_age_first_screen = localStorage.getItem(ExperimentsKeys.mg_body_age_first_screen);

    if (mg_personal_statement_screens === '1') {
        finalConfiguration = Object.assign(finalConfiguration, {
            [RoutePath.WorkoutEquipment]: {
                ...finalConfiguration[RoutePath.WorkoutEquipment],
                route: {
                    next: () => RoutePath.WorkoutMotivationRating
                },
            },
            [RoutePath.WorkoutMotivationRating]: {
                header: {
                    email: false,
                    logo: true,
                    progress: {
                        percents: 73,
                        label: '',
                    }
                },
                route: {
                    next: () => RoutePath.SupportMotivationRating
                },
                events: {
                    onLeave: () => 'MenWorkoutMotivationRatingClicked',
                },
                requiredKeys: [
                    LocalStorageKeys[RoutePath.BodyAge],
                    LocalStorageKeys[RoutePath.BodyFat],
                    MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
                    [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
                    [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs]
                ]
            },
            [RoutePath.SupportMotivationRating]: {
                header: {
                    email: false,
                    logo: true,
                    progress: {
                        percents: 74.5,
                        label: '',
                    }
                },
                route: {
                    next: () => RoutePath.OldRoutineRating
                },
                events: {
                    onLeave: () => 'MenSupportMotivationRatingClicked',
                },
                requiredKeys: [
                    LocalStorageKeys[RoutePath.BodyAge],
                    LocalStorageKeys[RoutePath.BodyFat],
                    MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
                    [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
                    [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs]
                ]
            },
            [RoutePath.OldRoutineRating]: {
                header: {
                    email: false,
                    logo: true,
                    progress: {
                        percents: 76,
                        label: '',
                    }
                },
                route: {
                    next: () => RoutePath.StickWorkoutRoutineRating
                },
                events: {
                    onLeave: () => 'MenOldRoutineRatingClicked',
                },
                requiredKeys: [
                    LocalStorageKeys[RoutePath.BodyAge],
                    LocalStorageKeys[RoutePath.BodyFat],
                    MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
                    [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
                    [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs]
                ]
            },
            [RoutePath.StickWorkoutRoutineRating]: {
                header: {
                    email: false,
                    logo: true,
                    progress: {
                        percents: 77.5,
                        label: '',
                    }
                },
                route: {
                    next: () => RoutePath.ConsistentExerciseRating
                },
                events: {
                    onLeave: () => 'MenStickWorkoutRoutineRatingClicked',
                },
                requiredKeys: [
                    LocalStorageKeys[RoutePath.BodyAge],
                    LocalStorageKeys[RoutePath.BodyFat],
                    MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
                    [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
                    [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs]
                ]
            },
            [RoutePath.ConsistentExerciseRating]: {
                header: {
                    email: false,
                    logo: true,
                    progress: {
                        percents: 79,
                        label: '',
                    }
                },
                route: {
                    next: () => RoutePath.PlanLoader
                },
                events: {
                    onLeave: () => 'MenConsistentExerciseRatingClicked',
                },
                requiredKeys: [
                    LocalStorageKeys[RoutePath.BodyAge],
                    LocalStorageKeys[RoutePath.BodyFat],
                    MultipleLocalStorageKeys.bodyHeightWeightKeys.unitSystem,
                    [MultipleLocalStorageKeys.bodyHeightWeightKeys.heightCm, MultipleLocalStorageKeys.bodyHeightWeightKeys.heightFt],
                    [MultipleLocalStorageKeys.bodyHeightWeightKeys.weightKg, MultipleLocalStorageKeys.bodyHeightWeightKeys.weightLbs]
                ]
            },
        });
    }

    if (mg_body_age_first_screen === '1') {
        finalConfiguration = Object.assign(finalConfiguration, {
            [RoutePath.DesiredLook]: {
                ...finalConfiguration[RoutePath.DesiredLook],
                requiredKeys: [
                    LocalStorageKeys[RoutePath.BodyAge]
                ]
            },
            [RoutePath.BodyPart]: {
                ...finalConfiguration[RoutePath.BodyPart],
                requiredKeys: [
                    LocalStorageKeys[RoutePath.BodyAge]
                ]
            },
            [RoutePath.Motivation]: {
                ...finalConfiguration[RoutePath.Motivation],
                route: {
                    next: () => RoutePath.BodyFat
                },
                requiredKeys: [
                    LocalStorageKeys[RoutePath.BodyAge]
                ]
            }
        });
    }

    return finalConfiguration;
};
