export const RoutePath = {
    MainPage: '/',
    BodyAgeFirstScreen: '/body-age-first-screen',
    DesiredLook: '/desired-look',
    BodyPart: '/body-part',
    BodyFat: '/body-fat',
    Motivation: '/motivation',
    BodyAge: '/body-age',
    BodyHeight: '/body-height',
    BodyWeight: '/body-weight',
    BodyIdealWeight: '/body-ideal-weight',
    BodyHealthSummary: '/body-health-summary',
    FitnessLevel: '/fitness-level',
    Workouts: '/workouts',
    WorkoutsTip: '/workouts-tip',
    PhysicalLimitations: '/physical-limitations',
    PhysicalLimitationsTip: '/physical-limitations-tip',
    PushUps: '/push-ups',
    PullUps: '/pull-ups',
    SleepHours: '/sleep-hours',
    SleepHoursTip: '/sleep-hours-tip',
    StressLevel: '/stress-level',
    StressLevelTip: '/stress-level-tip',
    AtIdealShape: '/at-ideal-shape',
    PastBlockers: '/past-blockers',
    PastBlockersTip: '/past-blockers-tip',
    FridgeFood: '/fridge-food',
    GlassesWater: '/glasses-water',
    GlassesWaterTip: '/glasses-water-tip',
    ConsumedItems: '/consumed-items',
    EatTimes: '/eat-times',
    SingleMeal: '/single-meal',
    SpecificDiet: '/specific-diet',
    SpecificDietTip: '/specific-diet-tip',
    IntermittentFasting: '/intermittent-fasting',
    IntermittentFastingTip: '/intermittent-fasting-tip',
    WorkoutTimes: '/workout-times',
    WorkoutLong: '/workout-long',
    WorkoutPlan: '/workout-plan',
    WorkoutPlace: '/workout-place',
    WorkoutEquipment: '/workout-equipment',
    CustomPlan: '/custom-plan',
    Email: '/email',
    NewsOffer: '/news-offer',
    PlanLoader: '/plan-loader',
    TrialPay: '/trial-pay',
    Paywall: '/paywall',
    UpgradePlan: '/upgrade-plan',
    Congrats: '/congrats',
    Welcome: '/welcome',

    Graphs: '/graphs',
    WorkoutMotivationRating: '/workout-motivation-rating',
    SupportMotivationRating: '/support-motivation-rating',
    OldRoutineRating: '/old-routine-rating',
    StickWorkoutRoutineRating: '/stick-workout-routine-rating',
    ConsistentExerciseRating: '/consistent-exercise-rating',

    PersonalizedProgram: '/personalized-program',
    YouAreAble: '/you-are-able',
};
