import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseContext } from 'context/base.context';

import { NextButton } from 'components/next-button';

import { AchievementWithAble } from './achievement-with-able';

export const Graphs = () => {
    const { t } = useTranslation();

    const { pageConfiguration } = useContext(BaseContext);

    const handleClick = () => {
        pageConfiguration.handleOnPageComplete();
    };

    return (
        <>
            <AchievementWithAble />

            <NextButton typographyText={t('graphs-button-next')} onClick={handleClick} />
        </>
    );
};
